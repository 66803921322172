@import url('https://fonts.googleapis.com/css2?family=Lora&family=Fascinate+Inline&display=swap');

.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  height: 100vh;
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.logo {
  font-family: "Fascinate Inline", serif;
  font-weight: 400;
  font-style: normal;
}

.App-link {
  color: #61dafb;
}

button {
  all: unset;
  color: #80CBC4;
  text-decoration: none;
}

button:hover {
  text-decoration: underline;
  cursor: pointer;
}

button:active {
  color: #02EE68;
}

button:visited {
  color: #FFA38C;
}

nav {
  display: flex;
  align-items: center;
  padding: 16px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  color: #CE93D8;
}

.site-header {
  background-color: #272727;
  flex: 0 1 auto;
}

.site-header nav ul li a {
  text-decoration: none;
  color: #fff;
  opacity: 0.87;
}

.site-header nav ul li a:hover {
  text-decoration: underline;
  color: #80CBC4;
}

footer {
  align-items: center;
  background-color: #272727;;
  flex-shrink: 0;
  z-index: 1;
}

.social-links {
  justify-content: center;
}

.feature-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  background-color: #121212;
}

.background {
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(221, 210, 210, 1),
    rgba(0, 0, 0, 0.6)
  );
  position: relative;
  background-size: cover;
}

@media only screen and (max-width: 450px) {
  .background {
    background-position: center;
  }
}

.about-me-container {
  display: flex;
  flex-direction: row-reverse;
  background-size: cover;
  flex-grow: 1;
  flex-shrink: 1;
}

.about-me {
  min-height: 600px;
  width: 25%;
  max-width: 30%;
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
  padding: 1em;
  margin-inline-end: 8%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: start;
}

@media only screen and (max-width: 450px) {
  .about-me {
    max-width: 75%;
    width: 100%;
  }
}

.about-me h1 {
  flex-grow: 3;
  margin: 0;
  display: inline-block;
  font-size: 4em;
}

.about-me h1:after {
  content:' ';
  display:block;
  border:2px solid #d0d0d0;
  border-radius:4px;
  -webkit-border-radius:4px;
  -moz-border-radius:4px;
  box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
  -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
  -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
}

.about-me h2 {
  flex-grow: 2;
  font-size: 1em;
  display: inline-block;
}

.about-me p {
  flex-grow: 1;
  margin: 0px;
}

.article-information {
  position: absolute;
  bottom: 0;
  padding: 8px;
  text-align: start;
}

.article-title, .article-date {
  margin: 0;
  text-shadow: 0 1px 0 black;
}

.article-date {
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  color: #fff;
  opacity: 0.87;
}

.article-title a {
  color: #fff;
  text-decoration: none;
}

.article-title a:hover {
  color: #80CBC4;
  text-decoration: underline;
}

.article-title a:active {
  color: #02EE68;
}

.article-title a:visited {
  color: #FFA38C;
}

.me {
  height: 10vmin;
  width: 15vmin;
  align-self: center;
  padding: 16px;
}

.post {
  display: flex;
  flex-direction: column;
  max-width: 64%;
  height: auto;
  margin: auto;
  background-color: #1d1d1d;
  color: #fff;
  text-align: justify;
  padding: 8px;
}

.post h1, .post p {
  opacity: 0.87;
}

@media only screen and (max-width: 450px) {
  .post {
    max-width: calc(100% - 16px);
  }
}

.post h1 {
  align-self: center;
}

.align-image {
  max-width: 100%;
  height: auto;
}

.first-div {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 450px) {
  .first-div {
    flex-direction: column;
  }
}

.inner {
  flex: 1;
  order: 1;
}

.inner-second {
  flex: 1;
  order: 2;
}

.error-header {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-header h1 {
  color: white;
  font-size: 4em;
}

.photo-layout-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.photo-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-inline-start: 0;
  margin: 0px;
}

.photo-list-item {
  height: 50vh;
  flex-grow: 1;
}

@media only screen and (max-width: 450px) {
  .photo-list-item {
    height: 20vh;
  }
}

.photo-item {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.posts-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 10px;
    margin: 8px;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    margin: auto;
    background-color: #1d1d1d;
    color: #fff;
    max-width: 64%;
    min-width: 64%;
}

@media only screen and (max-width: 450px) {
  .posts-container {
    margin-left: 8px;
    margin-right: 8px;
    max-width: 100%;
  }
}

.post-item {
  min-width: 350px;
  max-width: 350px;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 0;
  padding-top: 25.00%;
  margin: 8px;
  box-shadow: 1px 5px 5px black;
}

.post-item .information {
  background-color: #272727;
  box-shadow: 1px 5px 5px black;
  border-radius: 2px;
  text-align: start;
  padding-inline-start: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.post-item .title {
  margin: 0;
  text-decoration: none;
}

.post-item .title a, .post a {
  color: #80CBC4;
  text-decoration: none;
}

.post-item .title a:hover, .post a:hover {
  text-decoration: underline;
}

.post-item .title a:active, .post a:active {
  color: #02EE68;
}

.post-item .title a:visited, .post a:visited {
  color: #FFA38C;
}

.post-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: #121212;
}

.page-header {
  color: white;
  opacity: 0.87;
}

/* Solid border */
hr.solid {
  border-top: 3px solid #bbb;
  width: 100%;
}
